import type { Logger } from "@expert/logging";
import type { ExpertAssistMessage } from "../../shared-types";
import { botMessageTypes, isFromExpert } from "../../utils";
import { TimelineItem } from "../timeline-item";
import { getLastMessageOfType } from "../../state";
import { generateTimelineItemFromType } from "./generateTimelineItemForType";

interface GenerateTimelineItemProps {
    messages: ExpertAssistMessage[];
    logger: Logger;
    isExternal: boolean;
    scrollRef?: React.RefObject<HTMLDivElement>;
}

export function generateTimelineItems({
    messages = [],
    scrollRef,
    logger,
    isExternal = false,
}: GenerateTimelineItemProps) {
    const items: JSX.Element[] = [];

    const lastBotMessage = getLastMessageOfType(messages, botMessageTypes);
    const activeMessages = messages.filter((msg) => msg.status === "active");
    const lastProactiveMessage = getLastMessageOfType(activeMessages, ["Proactive", "Default", "SessionSummary"]);

    let loadingBotMessageItem = null;
    if (messages.length) {
        const mostRecentMessage = messages[messages.length - 1];
        const isLoadingBotMessage = isFromExpert(mostRecentMessage.type);
        if (isLoadingBotMessage) {
            loadingBotMessageItem = (
                <TimelineItem
                    isLastBotMessage
                    isLastProactiveMessage
                    isExternal={isExternal}
                    isLoading
                    key="LoadingBotMessage"
                    type="Proactive"
                    logger={logger}
                />
            );
        }
    }

    items.push(
        ...messages.map((msg) => (
            <TimelineItem
                isExternal={isExternal}
                isLastBotMessage={!loadingBotMessageItem && msg.id === lastBotMessage}
                isLastProactiveMessage={msg.id === lastProactiveMessage}
                message={msg}
                type={msg.type}
                isLoading={false}
                key={`timeline-item-${msg.id}-${msg.type}`}
                scrollRef={scrollRef}
                metadata={msg.metadata}
                logger={logger}
            >
                {generateTimelineItemFromType(msg)}
            </TimelineItem>
        )),
    );

    if (loadingBotMessageItem) items.push(loadingBotMessageItem);

    return items;
}
