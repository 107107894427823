import type { Logger } from "@expert/logging";
import type { Partner } from "@expert/shared-types";
import type useWebSocket from "react-use-websocket";

export type SendJsonMessage = ReturnType<typeof useWebSocket>["sendJsonMessage"];

// TODO: THIS IS A TEMPORARY HACK TO MAKE SURE TMO & OTHER SMALL PARTNERS DO NOT BREAK [EWS-1101]
export const SUPPORTED_GAIA_PARTNERS: Partner[] = ["verizon", "att", "mcafee", "asurion"];
export type SupportedPartners = Exclude<Partner, "tmobile" | "cricket" | "unknown">;

export interface GaiaSubscription {
    sessionId: string;
    callSid?: string;
    partner: Partner;
    sendJsonMessage: SendJsonMessage;
    logger: Logger;
    timeout?: number;
    explicitSubscriptions?: string[];
}

export interface GaiaUnsubscription {
    sessionId: string;
    sendJsonMessage: SendJsonMessage;
    logger: Logger;
}

export type SubscriptionStatus = "subscribed" | "subscribing" | "unsubscribed" | "unsubscribing";

export interface GaiaWebsocketContext {
    sendJsonMessage: SendJsonMessage;
    loading: boolean;
    subscribeSessionToGaia: ({
        sessionId,
        callSid,
        partner,
        sendJsonMessage,
        timeout,
        explicitSubscriptions,
    }: GaiaSubscription) => Promise<void>;
    unsubscribeSessionFromGaia: ({ sessionId, sendJsonMessage }: GaiaUnsubscription) => void;
}

export const GaiaWebSocketEvent = {
    // authorize
    Authorize: "authorize",
    AuthorizationSuccess: "authorization-success",
    UnauthorizedConnection: "unauthorized-connection",
    // subscribe/unsubscribe
    Subscribe: "subscribe",
    Unsubscribe: "unsubscribe",
    SessionSubscriptionSuccess: "session-subscription-success",
    SessionUnsubscriptionSuccess: "session-unsubscription-success",
    // reactive messages
    OrchestratorInvoke: "orchestrator-invoke",
    OrchestratorInvokeSuccess: "orchestrator-invoke-success",
    OrchestratorInvokeError: "orchestrator-invoke-error",
    // proactive messages
    MessageSuggestion: "message-suggestion",
    // session history
    FetchHistory: "fetch-orchestrator-history",
    FetchHistorySuccess: "fetch-orchestrator-history-success",
    FetchHistoryFailure: "fetch-orchestrator-history-error",
    // auto complete
    AutoComplete: "fetch-auto-complete-suggestion",
    AutoCompleteSuccess: "fetch-auto-complete-suggestion-success",
    AutoCompleteFailure: "fetch-auto-complete-suggestion-error",
    // outage information
    OutageInfo: "fetch-helix-news",
    OutageInfoSuccess: "fetch-helix-news-success",
    // transcript snippets
    TranscriptSnippets: "call-transcript",
} as const;

export type GaiaWebSocketEventType = (typeof GaiaWebSocketEvent)[keyof typeof GaiaWebSocketEvent];

export interface GaiaWebSocketResponse {
    body: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    correlationId: string;
    name: GaiaWebSocketEventType;
    timestamp: number;
}

export interface GaiaSubscriptionBody {
    sessionGroupId?: string;
    sessionId: string;
    suggestion?: {
        message: string;
        messageId: string;
    };
}
