import { generateOzmoHomeUrl } from "@expert/solve-tools";
import { LaunchOzmoIcon, NotepadIcon, PhoneActivationIcon, ResetChatIcon } from "../../assets";
import type { GuideCommand } from "../../shared-types";
import { useTimelineStore } from "../../state";

export type ToolboxAction = "OpenNotepad" | "ResetChat" | "LaunchOzmo" | "OpenGuide";
export interface MenuItemUI {
    label: string;
    icon: React.JSX.Element;
    disabled: boolean;
    actionId: ToolboxAction;
    quickActionLabel?: string;
    confirmationLabel?: string;
    tooltip?: string;
    additionalProps?: Record<string, unknown>;
}

export interface MenuItemProps {
    id: string;
    item: MenuItemUI;
    focusedMenuItem: string;
    onClick: (item: MenuItemUI) => void;
}

export interface ToolboxContext {
    guide?: GuideCommand;
    isOzmoEnabled?: boolean;
}

export const toolboxUIMapping = ({ guide, isOzmoEnabled = true }: ToolboxContext) => {
    const { callSid, partner, sessionId } = useTimelineStore.getState();

    return [
        {
            label: "Notepad",
            icon: <NotepadIcon />,
            quickActionLabel: "/n",
            disabled: false,
            actionId: "OpenNotepad",
        },
        {
            label: "Reset chat",
            icon: <ResetChatIcon />,
            quickActionLabel: "/r",
            disabled: false,
            actionId: "ResetChat",
            tooltip: "This’ll clear the chat",
            confirmationLabel: "This will clear chat history. Are you sure you want to reset the chat?",
        },
        isOzmoEnabled && {
            label: "Launch Ozmo",
            icon: <LaunchOzmoIcon />,
            quickActionLabel: "/o",
            disabled: false,
            actionId: "LaunchOzmo",
            additionalProps: {
                component: "a",
                href: generateOzmoHomeUrl(sessionId, partner, callSid),
                target: "_blank",
            },
        },
        guide && {
            icon: <PhoneActivationIcon />,
            actionId: "OpenGuide",
            ...guide,
        },
    ].filter((x) => !!x) as MenuItemUI[];
};
