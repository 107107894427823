import type { MessageType } from "@expert/shared-types";
import type { ExpertAssistMetadata } from "../../shared-types";
import { SessionSummaryHeader } from "./custom-messages/SessionSummary";

export function MessageBubbleHeader({
    isLastProactiveMessage,
    type,
    metadata,
}: {
    isLastProactiveMessage: boolean;
    type: MessageType;
    metadata?: ExpertAssistMetadata;
}) {
    if (type === "SessionSummary" && metadata?.sessionSummary) {
        return (
            <SessionSummaryHeader metadata={metadata.sessionSummary} isLastProactiveMessage={isLastProactiveMessage} />
        );
    }
}
