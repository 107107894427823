import type { SendJsonMessage } from "@expert/gaia";
import type { Partner } from "@expert/shared-types";

interface FetchMessageHistoryProps {
    sessionId: string;
    partner: Partner;
    lob?: string;
    sendJsonMessage: SendJsonMessage;
}

export function fetchMessageHistory({ sessionId, partner, lob, sendJsonMessage }: FetchMessageHistoryProps) {
    const fetchMessageHistoryPayload = {
        action: "fetch-orchestrator-history",
        appName: "expert-assist",
        correlationId: crypto.randomUUID(),
        partner,
        sessionGroupId: sessionId,
        lineOfBusiness: lob,
    };
    sendJsonMessage(fetchMessageHistoryPayload);
}
