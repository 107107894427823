import { useQuery } from "@tanstack/react-query";
import { getRecordedLegalDisclosure } from "../api";
import { useContentfulClient } from "../ContentfulClientProvider";
import type { Language } from "../types";
import { getLogger } from "@expert/logging";

const logger = getLogger({
    module: "recorded-disclosure",
    supportTeam: "sales",
});

export const useRecordedLegalDisclosure = (productSku: string, language: Language = "en-US") => {
    const contentfulClient = useContentfulClient();
    const { data, isError, isLoading } = useQuery({
        queryKey: [`recorded-disclosure-${productSku}-${language}`],
        queryFn: async () => await getRecordedLegalDisclosure(contentfulClient, productSku, language),
    });

    if (data) {
        return data;
    }

    if (isError || (!data && !isLoading)) {
        logger.error({ productSku, language }, "Failed to find appropriate recorded disclosure");
    }

    return null;
};
