import { Box, Flex, Group, Text, type TextProps } from "@mantine/core";
import { MarkdownViewer } from "@expert/solve-tools";
import { NotesStarIcon } from "../../../assets/NotesStarIcon";
import type { SessionSummaryMetadata } from "../../../shared-types";

const headings = ["Call reason", "Action taken", "Call conclusion", "Follow ups", "Additional info"];
function HeadingText(props: TextProps) {
    return <Text size="sm" fw={700} {...props} pt="sm" />;
}
export function generateSessionSummaryItem(metadata: SessionSummaryMetadata) {
    // regex to find occurrences of headings & inject heading4 markdown for styling purposes
    const headingRegex = new RegExp(headings.join("|"), "gi");
    const summaryWithMarkdown = metadata.summary.replace(headingRegex, "#### $&");
    const mt = metadata.summary.includes(headings[0]) ? "-0.5rem" : "0";
    return (
        <Flex direction="column" mt={mt}>
            {metadata.resolutionStatus && <ResolutionTag resolutionStatus={metadata.resolutionStatus} />}
            <MarkdownViewer content={summaryWithMarkdown} options={{ overrides: { h4: HeadingText } }} />
        </Flex>
    );
}

export function SessionSummaryHeader({
    metadata,
    isLastProactiveMessage = true,
}: {
    metadata: SessionSummaryMetadata;
    isLastProactiveMessage: boolean;
}) {
    const isPrevious = metadata.sessionType === "previous";

    const dateString = metadata.timestamp
        ? new Date(metadata.timestamp).toLocaleDateString("en-us", {
              month: "short",
              day: "numeric",
          })
        : undefined;

    const previousContent = dateString ? (
        <>
            <strong>{metadata.header}: </strong>
            {dateString} summary
        </>
    ) : (
        <strong>{metadata.header}</strong>
    );

    const currentContent = (
        <>
            <strong>{dateString ? dateString + " " : metadata.header}</strong>
            {dateString && metadata.header.toLowerCase()}
        </>
    );

    const content = isPrevious ? previousContent : currentContent;

    return (
        <Group
            gap="xs"
            p="0.5rem 1rem"
            bg={isLastProactiveMessage ? "linear-gradient(to right, #8223d2, #5034cf)" : "var(--mantine-color-dark-4)"}
            styles={{ root: { borderTopLeftRadius: "1.25rem", borderTopRightRadius: "1.25rem" } }}
        >
            <NotesStarIcon />
            <Text size="sm">{content}</Text>
        </Group>
    );
}

interface ResolutionTagProps {
    resolutionStatus: string;
}

function ResolutionTag({ resolutionStatus }: ResolutionTagProps) {
    let backgroundColor = "var(--mantine-color-dark-4)";
    let textColor = "var(--mantine-color-white)";

    switch (resolutionStatus) {
        case "resolved":
            backgroundColor = "var(--mantine-color-success-9)";
            textColor = "var(--mantine-color-white)";
            break;
        case "not resolved":
            backgroundColor = "var(--mantine-color-error-9)";
            textColor = "var(--mantine-color-white)";
            break;
        case "transferred":
            backgroundColor = "var(--mantine-color-warning-4)";
            textColor = "var(--mantine-color-black)";
            break;
    }

    return (
        <Box
            style={{
                padding: "0.25rem 0.5rem",
                borderRadius: "0.25rem",
                backgroundColor,
                marginRight: "auto",
                marginBottom: "0.5rem",
                marginTop: "0.5rem",
            }}
        >
            <Text size="sm" style={{ color: textColor }}>
                {resolutionStatus.charAt(0).toUpperCase() + resolutionStatus.slice(1)}
            </Text>
        </Box>
    );
}
