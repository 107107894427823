import { Button, type ButtonProps, Space } from "@mantine/core";
import { forwardRef } from "react";
import { CaretDown, CaretUp } from "../assets";
import classes from "./styles.module.css";

interface GuideMenuButtonProps extends ButtonProps {
    label: string;
    menuOpened: boolean;
    validationError?: string;
}

const styles = {
    invalid: { c: "var(--mantine-color-red-text)", bd: "1px solid var(--mantine-color-red-text)" },
    valid: {
        bd: `0.0625rem solid var(--mantine-color-dark-3)`,
    },
};

export const GuideMenuButton = forwardRef<HTMLButtonElement, GuideMenuButtonProps>(function GuideMenuButton(
    { label, menuOpened, validationError, ...rest }: GuideMenuButtonProps,
    ref,
) {
    const caretFill = validationError ? "var(--mantine-color-red-text)" : undefined;
    return (
        <Button
            className={classes.menuButton}
            mr="lg"
            mb="sm"
            size="xs"
            fz="xs"
            variant="outline"
            color="text"
            radius="default"
            {...(validationError ? styles.invalid : styles.valid)}
            ref={ref}
            {...rest}
        >
            {label}
            <Space w="xs" />
            {menuOpened ? <CaretUp fill={caretFill} /> : <CaretDown fill={caretFill} />}
        </Button>
    );
});
