import { create } from "zustand";
import { devtools, persist, subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import type { ExpertAssistMessage } from "../shared-types";
import { fallbackDefaultMessages } from "./fallbackDefaultMessages";
import type { Partner } from "@expert/shared-types";

interface Expert {
    expertName: string;
    expertId: string;
}

interface TimelineMetaProps {
    partner: Partner;
    sessionId: string;
    callSid?: string;
    lob?: string;
}
export interface TimelineStore {
    expertId: string;
    expertName: string;
    setExpert: ({ expertId, expertName }: Expert) => void;

    partner: Partner;
    sessionId: string;
    callSid?: string;
    lob?: string;
    setTimelineProperties: ({ partner, sessionId, callSid, lob }: TimelineMetaProps) => void;

    defaultMessages: ExpertAssistMessage[];
    setDefaultMessages: (defaultMessages: ExpertAssistMessage[]) => void;

    messages: ExpertAssistMessage[];
    addMessage: (message: ExpertAssistMessage) => void;
    addMessages: (messages: ExpertAssistMessage[]) => void;
    resetMessages: (messages: ExpertAssistMessage[]) => void;
    updateMessage: (id: string, message: Partial<ExpertAssistMessage>) => void;
    setIsMessageUnread: (messageId: string, isUnread: boolean) => void;
    markAllMessagesAsRead: () => void;

    shouldAutoOpenToolbox: boolean;
    setShouldAutoOpenToolbox: (val: boolean) => void;

    autoCompleteSuggestions: string[];
    setNewSuggestions: (suggestions: string[]) => void;
}

export const useTimelineStore = create<TimelineStore>()(
    subscribeWithSelector(
        immer(
            devtools(
                persist(
                    (set) => ({
                        setExpert: ({ expertId, expertName }: Expert) =>
                            set({ expertId, expertName }, false, "setExpert"),
                        expertId: "",
                        expertName: "",
                        setTimelineProperties: ({ partner, sessionId, callSid, lob }: TimelineMetaProps) =>
                            set({ partner, sessionId, lob, callSid }, false, "setTimelineProperties"),
                        partner: "asurion",
                        sessionId: "",
                        callSid: undefined,
                        lob: undefined,
                        defaultMessages: fallbackDefaultMessages,
                        setDefaultMessages: (defaultMessages: ExpertAssistMessage[]) => {
                            set(
                                (state) => {
                                    state.defaultMessages = defaultMessages;
                                },
                                false,
                                "setDefaultMessages",
                            );
                        },
                        messages: [],
                        addMessage: (message: ExpertAssistMessage) => {
                            set(
                                (state) => {
                                    state.messages = [...state.messages, message];
                                },
                                false,
                                "addMessage",
                            );
                        },
                        addMessages: (messages: ExpertAssistMessage[]) => {
                            set(
                                (state) => {
                                    state.messages = [...state.messages, ...messages];
                                },
                                false,
                                "addMessages",
                            );
                        },
                        resetMessages: (messages: ExpertAssistMessage[]) => {
                            set(
                                (state) => {
                                    state.messages = [...messages];
                                },
                                false,
                                "resetMessages",
                            );
                        },
                        updateMessage: (id: string, message: Partial<ExpertAssistMessage>) => {
                            set(
                                (state) => {
                                    const index = state.messages.findIndex((msg) => msg.id === id);
                                    if (index > 0) {
                                        state.messages[index] = { ...state.messages[index], ...message };
                                    }
                                },
                                false,
                                "updateMessage",
                            );
                        },
                        setIsMessageUnread: (messageId: string, isUnread: boolean) => {
                            set(
                                (state) => {
                                    state.messages = state.messages.map((message) =>
                                        message.id === messageId ? { ...message, isUnread } : message,
                                    );
                                },
                                false,
                                "setIsMessageUnread",
                            );
                        },
                        markAllMessagesAsRead: () => {
                            set(
                                (state) => {
                                    state.messages = state.messages.map((message) =>
                                        message.isUnread === true ? { ...message, isUnread: false } : message,
                                    );
                                },
                                false,
                                "markAllMessagesAsRead",
                            );
                        },
                        shouldAutoOpenToolbox: true,
                        setShouldAutoOpenToolbox: (val: boolean) => {
                            set((state) => {
                                state.shouldAutoOpenToolbox = val;
                            });
                        },
                        autoCompleteSuggestions: [],
                        setNewSuggestions: (values: string[]) => {
                            set(
                                (state) => {
                                    state.autoCompleteSuggestions = [...values];
                                },
                                false,
                                "setNewSuggestions",
                            );
                        },
                    }),

                    {
                        name: "timelineStore",
                        partialize: (state: TimelineStore) => ({
                            shouldAutoOpenToolbox: state.shouldAutoOpenToolbox,
                        }),
                    },
                ),
                { enabled: import.meta.env.VITE_ENV_TYPE !== "production", store: "expert-assist", name: "timeline" },
            ),
        ),
    ),
);
