import { useEffect, useMemo, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import type { Logger } from "@expert/logging";
import { GAIA_WS_EMITTER_NAME, gaiaWsEventBus } from "../gaiaEventBus";
import type {
    GaiaSubscription,
    GaiaSubscriptionBody,
    GaiaUnsubscription,
    GaiaWebsocketContext,
    GaiaWebSocketResponse,
} from "../types";
import { GaiaWebSocketEvent } from "../types";
import { useGaiaStatusEvents } from "./useGaiaStatusEvents";

const heartbeatInterval = 20000;
const heartbeatTimeout = 90000;

interface GaiaWebsocket {
    baseUrl: string;
    application: string;
    token: string;
    identity: string;
    logger: Logger;
}

export function useGaiaWebsocketInternal({ baseUrl, application, token, identity, logger: loggerProp }: GaiaWebsocket) {
    const [loading, setLoading] = useState(true);
    const url = `${baseUrl}?application=${application}&token=${token}`;
    const logger = useMemo(() => loggerProp.child({ module: "useGaiaWebsocket", supportTeam: "solve" }), [loggerProp]);

    const { sendJsonMessage, readyState } = useWebSocket(
        url,
        {
            heartbeat: {
                interval: heartbeatInterval,
                timeout: heartbeatTimeout,
                message: JSON.stringify({
                    action: "heartbeat",
                    correlationId: identity,
                }),
            },
            shouldReconnect: (_closeEvent) => true,
            reconnectAttempts: 10,
            reconnectInterval: 2000,
            onMessage: createOnMessageEventHandler(setLoading, logger),
            onError: (event: Event) => onError(event, logger),
        },
        true,
    );

    useGaiaStatusEvents(logger);

    useEffect(() => {
        if (!loading && readyState === ReadyState.CONNECTING) {
            logger.debug("GAIA | websocket connecting");
            setLoading(true);
        }
    }, [loading, readyState, logger]);

    useEffect(() => {
        if (loading && readyState === ReadyState.OPEN) {
            logger.info("GAIA | websocket open");
            sendJsonMessage({
                action: "authorize",
                token,
                correlationId: identity,
            });
            logger.debug("GAIA | authorize message sent");
        }
    }, [loading, readyState, identity, token, sendJsonMessage, logger]);

    useEffect(() => {
        if (!loading && readyState === ReadyState.CLOSED) {
            logger.info("GAIA | websocket closed");
            setLoading(false);
        }
    }, [loading, readyState, logger]);

    const subscriptionResult: GaiaWebsocketContext = useMemo(() => {
        return {
            sendJsonMessage,
            loading,
            subscribeSessionToGaia: subscribeSessionToGaia(identity),
            unsubscribeSessionFromGaia: unsubscribeSessionFromGaia(identity),
        };
    }, [sendJsonMessage, loading, identity]);

    return subscriptionResult;
}

const createOnMessageEventHandler =
    (onAuthenticated: (isLoading: boolean) => void, loggerProp: Logger) => (event: MessageEvent) => {
        try {
            const data = JSON.parse(event.data as string) as GaiaWebSocketResponse;
            const { body } = data;
            const logger = getLoggerContextFromBody(body, loggerProp);

            if (data.name.includes("error")) {
                logger.error({ body }, `GAIA | message received: ${GAIA_WS_EMITTER_NAME}_${data.name}`);
            } else logger.debug({ body }, `GAIA | message received: ${GAIA_WS_EMITTER_NAME}_${data.name}`);

            gaiaWsEventBus.emit(`${GAIA_WS_EMITTER_NAME}_${data.name}`, data);
            if (data.name === GaiaWebSocketEvent.AuthorizationSuccess) {
                onAuthenticated(false);
            }
        } catch (err: unknown) {
            loggerProp.error({ err, ...event }, "GAIA | message handler error");
        }
    };

const onError = (event: Event, logger: Logger) => {
    logger.error({ event: JSON.stringify(event) }, "GAIA | websocket error");
};

export const subscribeSessionToGaia =
    (identity: string) =>
    ({
        sessionId,
        partner,
        sendJsonMessage,
        callSid,
        timeout = 5000,
        logger: loggerProp,
        explicitSubscriptions,
    }: GaiaSubscription) => {
        const logger = loggerProp.child({ module: "useGaiaWebsocket" });
        return new Promise<void>((resolve, reject) => {
            sendJsonMessage({
                action: "subscribe",
                sessionGroupId: sessionId,
                sessionId: callSid ?? sessionId,
                explicitSubscriptions,
                partner,
                correlationId: identity,
            });
            logger.debug("GAIA | session subscribing");

            const timer = setTimeout(() => {
                cleanupEventListener();
                logger.warn("GAIA | session subscription timeout");
                reject(Error("GAIA session subscription timed out"));
            }, timeout);

            const cleanupEventListener = gaiaWsEventBus.once("gaia_ws_session-subscription-success", () => {
                clearTimeout(timer);
                resolve();
            });
        });
    };

const unsubscribeSessionFromGaia =
    (identity: string) =>
    ({ sessionId, sendJsonMessage, logger }: GaiaUnsubscription) => {
        sendJsonMessage({
            action: "unsubscribe",
            correlationId: identity,
            sessionId,
        });
        logger.child({ sessionId }).debug("GAIA | session unsubscribing");
    };

const getLoggerContextFromBody = (body: unknown, logger: Logger) => {
    const { sessionGroupId, sessionId: sessionIdFromBody } = body as Partial<GaiaSubscriptionBody>;
    if (sessionGroupId ?? sessionIdFromBody) {
        /* The sessionGroupId is a term used by GAIA. The value being used is the sessionId.
                In the event there is a call, sessionId is the callSid. It's stupid, we know. */

        const isThereACallSid = sessionGroupId && sessionIdFromBody && sessionIdFromBody !== sessionGroupId;
        const callSid = isThereACallSid ? sessionIdFromBody : undefined;
        const sessionId = sessionGroupId ?? sessionIdFromBody;

        return logger.child({ sessionId, callSid });
    }
    return logger;
};
