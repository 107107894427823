import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { Group } from "@mantine/core";
import type { Logger } from "@expert/logging";
import { trackAppcuesEvent } from "@expert/monitoring";
import { useSendExpertAssistMessage } from "../../hooks";
import { useAutoscroll } from "../../utils";
import type { QuickAction } from "./types";
import { QuickActionButton } from "./QuickActionButton";

interface ActionGroupProps {
    actions: QuickAction[];
    setIsQuickActionsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    logger: Logger;
    isExternal: boolean;
    scrollRef?: React.RefObject<HTMLDivElement>;
}

export function QuickActionGroup({
    setIsQuickActionsVisible,
    actions,
    logger,
    isExternal = false,
    scrollRef,
}: ActionGroupProps): JSX.Element {
    const sendMessage = useSendExpertAssistMessage(logger, isExternal);
    const { dispatcher } = useReactAnalytics();
    const { start: startAutoscroll } = useAutoscroll(scrollRef);
    const onQuickActionClick = ({
        value,
        analytics: { actionId, eventData, extraContext },
        onClick,
        isVisibleAfterClick,
    }: QuickAction) => {
        void dispatcher.dispatchBusinessEvent(`Click_${actionId}`, {
            ...eventData,
            ...extraContext,
            button: value,
        });
        trackAppcuesEvent(`Click_${actionId}`, { actionId, actionType: "QuickAction" });

        if (!isVisibleAfterClick) {
            setIsQuickActionsVisible(false);
        }

        if (onClick) {
            onClick(value);
        } else {
            sendMessage({ text: value, type: "QuickAction", source: "QuickAction" });
            startAutoscroll();
        }
    };

    const children = actions.map((action) => (
        <QuickActionButton
            key={`${action.analytics.actionId}-${action.value}`}
            name={action.analytics.actionId}
            value={action.value}
            onClick={() => onQuickActionClick(action)}
        />
    ));

    return (
        <Group gap="sm" mt="xs">
            {children}
        </Group>
    );
}
