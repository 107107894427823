import { getLogger } from "@expert/logging";
import type { Partner } from "@expert/shared-types";
import type { ContentfulClientApi, EntryFieldTypes, EntrySkeletonType } from "contentful";

export interface PersonalizeCoverageResponse {
    deviceName: string;
    examples: string[];
}

interface ExamplesData {
    generic: string[];
    [key: string]: string[];
}

interface PersonalizeCoverageFields {
    deviceName: EntryFieldTypes.Symbol;
    examples: EntryFieldTypes.Object;
}

const logger = getLogger({
    module: "personalize-coverage",
    supportTeam: "sales",
});

export const getPersonalizeCoverageExamples = async (
    contentfulClient: ContentfulClientApi<undefined>,
    partner: Partner,
): Promise<PersonalizeCoverageResponse[]> => {
    try {
        const entries = await contentfulClient.withoutUnresolvableLinks.getEntries<
            EntrySkeletonType<PersonalizeCoverageFields>
        >({
            content_type: "personalizeCoverage",
        });

        if (!entries.items.length) {
            logger.warn("No personalizeCoverage entries found.");
            return [];
        }
        const partnerKey = partner === "verizon" ? "product_vhdp" : "product_htp";

        return entries.items.map((entry) => {
            const { deviceName, examples } = entry.fields;
            const exampleData = examples as ExamplesData;

            const genericExamples = Array.isArray(exampleData.generic) ? exampleData.generic : [];
            const partnerExamples = Array.isArray(exampleData[partnerKey]) ? exampleData[partnerKey] : [];

            return {
                deviceName,
                examples: [...genericExamples, ...partnerExamples],
            };
        });
    } catch (error) {
        logger.error("Error fetching personalize coverage examples:", error);
        return [];
    }
};
