import { useEffect, useMemo } from "react";
import type { Logger } from "@expert/logging";
import { gaiaWsEventBus } from "../gaiaEventBus";
import { useGaiaStore } from "../../store";
import type { GaiaSubscriptionBody } from "../types";
import { sendMetrics } from "@expert/cloudwatch-metrics";

export const useGaiaStatusEvents = (loggerProp: Logger) => {
    const { setSubscriptionStatus, setIsWsAuthenticated, isWsAuthenticated } = useGaiaStore();
    const logger = useMemo(() => loggerProp.child({ module: "useGaiaStatusEvents" }), [loggerProp]);

    useEffect(() => {
        const gaiaEventBusAuthSuccess = gaiaWsEventBus.on("gaia_ws_authorization-success", () => {
            if (!isWsAuthenticated) {
                setIsWsAuthenticated(true);
                logger.info("GAIA | websocket authorized");

                void sendMetrics([
                    {
                        metrics: [{ name: "Success", value: 1 }],
                        dimensions: { action: "gaia.auth" },
                    },
                ]);
            }
        });
        const gaiaEventBusAuthFailure = gaiaWsEventBus.on("gaia_ws_unauthorized-connection", ({ data, timestamp }) => {
            setIsWsAuthenticated(false);
            logger.error(
                { ...(data as unknown as object), responseTimestamp: timestamp },
                "GAIA | websocket unauthorized",
            );

            void sendMetrics([
                {
                    metrics: [{ name: "Failure", value: 1 }],
                    dimensions: { action: "gaia.auth" },
                },
            ]);
        });
        return () => {
            gaiaEventBusAuthSuccess();
            gaiaEventBusAuthFailure();
        };
    }, [setIsWsAuthenticated, isWsAuthenticated, logger]);

    useEffect(() => {
        const gaiaEventBusSubSuccess = gaiaWsEventBus.on("gaia_ws_session-subscription-success", (payload) => {
            const { sessionGroupId, sessionId } = payload.body as GaiaSubscriptionBody;
            const subscriptionKey = sessionGroupId ?? sessionId;
            if (subscriptionKey) {
                setSubscriptionStatus(subscriptionKey, "subscribed");
                void sendMetrics([
                    {
                        metrics: [{ name: "Success", value: 1 }],
                        dimensions: { action: "gaia.subscribe" },
                    },
                ]);
            }
        });
        const gaiaEventBusUnsubSuccess = gaiaWsEventBus.on("gaia_ws_session-unsubscription-success", (payload) => {
            const { sessionId } = payload.body as GaiaSubscriptionBody;
            if (sessionId) {
                setSubscriptionStatus(sessionId, "unsubscribed");
                void sendMetrics([
                    {
                        metrics: [{ name: "Success", value: 1 }],
                        dimensions: { action: "gaia.unsubscribe" },
                    },
                ]);
            }
        });
        return () => {
            gaiaEventBusSubSuccess();
            gaiaEventBusUnsubSuccess();
        };
    }, [setSubscriptionStatus]);
};
