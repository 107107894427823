import { useEffect } from "react";
import { RootAnalyticsProvider } from "@soluto-private/eventualize-react";
import { GaiaWebsocketProvider } from "@expert/gaia";
import type { ReusableMainContainerProps } from "../components";
import { SidebarFeedbackButton, Header, PageContainer, ReusableMainContainer, StandaloneTimeline } from "../components";
import { useInitializers } from "../initializers";
import { useLoggerWithContextStore, useExpertAssistStore } from "../state";
import { env } from "../utils/env";
import { GlobalLoader } from "@expert/common-ui";
import classes from "./Home.module.css";

function MainContainer(props: Omit<ReusableMainContainerProps, "className">) {
    return <ReusableMainContainer {...props} className={classes.mainContainer} />;
}

export function Home() {
    useInitializers();
    const { loggerWithContext } = useLoggerWithContextStore();
    const { sessionId, token, expertProfile } = useExpertAssistStore();

    useEffect(() => {
        loggerWithContext.info({ module: "Home", domain: window.location.hostname }, "Home page loaded");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!sessionId) return <GlobalLoader msg="Loading Expert Assist..." />;

    return (
        <RootAnalyticsProvider>
            <GaiaWebsocketProvider
                logger={loggerWithContext}
                identity={expertProfile?.id ?? expertProfile.email /*e2e users don't have ids */}
                application="expert-workspace" // TODO: update this to expert-assist once supported by gaia
                token={token ?? ""}
                url={env.gaiaWebsocketUrl}
            >
                <PageContainer>
                    <Header />
                    <MainContainer direction="column" justify="flex-end" pb="0.75rem" pt="3rem">
                        <StandaloneTimeline />
                    </MainContainer>
                    <SidebarFeedbackButton />
                </PageContainer>
            </GaiaWebsocketProvider>
        </RootAnalyticsProvider>
    );
}
