import { AsurionLogoDark } from "@expert/common-ui/assets/AsurionLogoDark";
import { Container } from "@mantine/core";
import classes from "./BotIcon.module.css";
import BubbleLoader from "./BubbleLoader";
import { BotIconWrapper, LogoWrapper } from "./elements";
import type { BotIconProps } from "./types";

export function BotIcon({ isLoading, isHidden, isBotListening }: BotIconProps) {
    return (
        <BotIconWrapper data-testid="bot-icon-wrapper">
            {!isHidden && (
                <>
                    <LogoWrapper data-testid="logo-wrapper">
                        <Container className={classes.loaderLogo}>
                            <AsurionLogoDark />
                        </Container>
                    </LogoWrapper>
                    <BubbleLoader
                        className={`${classes.styledBubbleLoader} ${isBotListening ? classes.listeningBubbles : ""}`}
                        isBotListening={isBotListening}
                        isLoading={isLoading}
                    />
                </>
            )}
        </BotIconWrapper>
    );
}
export default BotIcon;
