import { useCallback, useMemo, useRef, useState } from "react";
import { Menu, Tooltip, UnstyledButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { NoteDialog } from "@soluto-private/expert-workspace-notepad";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import type { Logger } from "@expert/logging";
import { trackAppcuesEvent } from "@expert/monitoring";
import { ToolboxHoverIcon, ToolboxIcon, ToolboxMenuCloseIcon } from "../../assets";
import { useTimelineStore } from "../../state";
import { getDefaultMessage } from "../../utils";
import type { ToolboxContext } from "./types";
import { type MenuItemUI, toolboxUIMapping } from "./types";
import { ToolboxMenuItem } from "./ToolboxMenuItem";
import { UnreachableError } from "../../utils/errors";
import { ToolboxActionConfirmation } from "./ToolboxActionConfirmation";

interface ToolboxProps {
    input?: string;
    logger: Logger;
    opened: boolean;
    toolboxContext: ToolboxContext;
    setOpened: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
    trapFocus: boolean;
}

export function ToolboxButton({
    opened,
    setOpened,
    input,
    trapFocus,
    onClose,
    toolboxContext,
    logger: loggerProp,
}: ToolboxProps) {
    const { dispatcher } = useReactAnalytics();
    const focusedMenuItem = useRef("");
    const [isHovered, setIsHovered] = useState(false);
    const logger = useMemo(() => loggerProp.child({ module: "ToolboxButton" }), [loggerProp]);
    const [isNoteDialogOpened, { open: openNotepad, close: closeNotepad }] = useDisclosure(false, {
        onOpen: () => {
            logger.info("Notepad opened");
        },
        onClose: () => {
            logger.info("Notepad closed");
        },
    });
    const [isActionConfirmationOpen, setIsActionConfirmationOpen] = useState(false);
    const [currentActionItem, setCurrentActionItem] = useState<MenuItemUI>();
    const { resetMessages } = useTimelineStore();

    const handleButtonClick = () => {
        logger.info(`Toolbox ${!opened ? "opened" : "closed"}`);
        const actionId = opened ? "CloseToolbox" : "OpenToolbox";
        void dispatcher.dispatchAction("Click", actionId, {});
    };

    const handleMenuClose = () => {
        focusedMenuItem.current = "";
        onClose();
    };

    const handleMenuButtonClick = useCallback(
        (item: MenuItemUI) => {
            // NOTE: using business events for toolbox clicks for now to enable event usage in EDP for reporting.
            // We should use UserAction events for button clicks in the future once the piloting table is setup in EDP.
            void dispatcher.dispatchBusinessEvent(`Click_Toolbox_${item.actionId}`, {
                button: item.actionId,
                actionId: item.actionId,
            });
            trackAppcuesEvent(`Click_Toolbox_${item.actionId}`, { actionId: item.actionId, actionType: "Toolbox" });
            setCurrentActionItem(item);

            if (item.confirmationLabel) {
                logger.info(
                    { actionId: item.actionId },
                    "Toolbox | Action with confirmation clicked - confirming action",
                );
                setIsActionConfirmationOpen(true);
                return;
            }

            switch (item.actionId) {
                case "OpenNotepad":
                    openNotepad();
                    break;
                case "LaunchOzmo":
                    logger.info("Toolbox | Ozmo home page opened");
                    void dispatcher.dispatchBusinessEvent("Ozmo_OpenHomepage", {
                        source: "Toolbox",
                        ozmoUrl: item.additionalProps?.href,
                        ozmoTitle: item.label,
                    });
                    break;
                case "ResetChat":
                    // requires confirmation
                    break;
                case "OpenGuide": {
                    toolboxContext.guide?.open("toolbox");
                    break;
                }
                default:
                    throw new UnreachableError(item.actionId);
            }
        },
        [dispatcher, toolboxContext.guide, logger, openNotepad],
    );

    const onActionConfirmed = () => {
        logger.info({ actionId: currentActionItem?.actionId }, "Toolbox | Confirm action clicked");
        setIsActionConfirmationOpen(false);

        switch (currentActionItem?.actionId) {
            case "ResetChat":
                resetMessages([
                    {
                        ...getDefaultMessage("manualReset"),
                        id: `manualReset_${crypto.randomUUID()}`,
                        isUnread: true,
                    },
                ]);
                break;
            default:
                logger.warn(
                    { actionId: currentActionItem?.actionId },
                    "Toolbox | Unsupported action confirmation flow",
                );
                break;
        }
    };

    const Toolbox = isHovered ? <ToolboxHoverIcon /> : <ToolboxIcon />;

    const menuItems = useMemo(() => {
        return toolboxUIMapping(toolboxContext).map((item) => {
            const id = `toolbox-menu-item-${item.quickActionLabel ?? item.label}`;
            // if input matches a quick action, the menu item should autofocus
            if (input && input === item.quickActionLabel) {
                focusedMenuItem.current = id;
                void dispatcher.dispatchAction("Shortcut_Toolbox", item.actionId, {});
            }

            return (
                <Tooltip
                    disabled={!item.tooltip}
                    key={item.label}
                    label={item.tooltip}
                    openDelay={1000}
                    position="top-start"
                >
                    <ToolboxMenuItem
                        id={id}
                        item={item}
                        focusedMenuItem={focusedMenuItem.current}
                        onClick={handleMenuButtonClick}
                    />
                </Tooltip>
            );
        });
    }, [dispatcher, toolboxContext, handleMenuButtonClick, input]);

    // if menu is opened and shortcut input doesn't match a shortcut - close the menu
    const shouldCloseMenu = opened && !focusedMenuItem.current && input && input.length > 1;
    if (focusedMenuItem.current) {
        document.getElementById(focusedMenuItem.current)?.focus();
    } else if (shouldCloseMenu) {
        setOpened(false);
    }

    return (
        <>
            <Menu
                arrowOffset={20}
                onChange={setOpened}
                onClose={handleMenuClose}
                opened={opened && !shouldCloseMenu}
                position="top-start"
                shadow="sm"
                transitionProps={{ duration: 200, timingFunction: "ease", transition: "rotate-left" }}
                withArrow
                id="toolbox-menu"
                trapFocus={trapFocus}
                clickOutsideEvents={["click", "Escape"]}
            >
                <Tooltip disabled={opened} label="Toolbox" openDelay={1000}>
                    <Menu.Target>
                        <UnstyledButton
                            color="gray"
                            data-testid="toolbox-button"
                            pt=".25rem"
                            size="sm"
                            type="submit"
                            variant="subtle"
                            onClick={handleButtonClick}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            {opened ? <ToolboxMenuCloseIcon /> : Toolbox}
                        </UnstyledButton>
                    </Menu.Target>
                </Tooltip>
                <Menu.Dropdown>
                    <Menu.Item rightSection="/" disabled fz="xs">
                        Toolbox
                    </Menu.Item>
                    {menuItems}
                </Menu.Dropdown>
            </Menu>
            <NoteDialog isOpen={isNoteDialogOpened} onClose={closeNotepad} logger={logger} />
            <ToolboxActionConfirmation
                title={currentActionItem?.confirmationLabel ?? "Are you sure?"}
                isOpen={isActionConfirmationOpen}
                onClose={() => {
                    logger.info(`Toolbox | Cancelled action`);
                    setIsActionConfirmationOpen(false);
                }}
                onSubmit={onActionConfirmed}
                extraData={{ actionId: currentActionItem?.actionId }}
            />
        </>
    );
}
