import type { Partner, Tutorial } from "@expert/shared-types";
import { OZMO_BASE_URL } from "../../environment";

const partnerUrlMap: Record<string, string> = {
    verizon: "manufacturers/16/",
    att: "manufacturers/57/",
    default: "",
};

function getUrlQueryParams(sessionId: string, callSid?: string) {
    const analyticsContactID = callSid ? `${sessionId}_${callSid}` : sessionId;
    return `analyticsContactID=${analyticsContactID}&analyticsCustom1=ExpertAssist`;
}

export function generateOzmoHomeUrl(sessionId: string, partner: Partner, callSid?: string) {
    const partnerSpecificUrlSegment = partnerUrlMap[partner] ?? partnerUrlMap.default;
    return `${OZMO_BASE_URL}agents/en/${partnerSpecificUrlSegment}?${getUrlQueryParams(sessionId, callSid)}`;
}

export function generateOzmoEmulatorUrl(model: string, sessionId: string, callSid?: string) {
    const encodedDeviceModel = encodeURIComponent(model);
    return `${OZMO_BASE_URL}hsd86n1e/en-us/virtual-device/model/${encodedDeviceModel}/?${getUrlQueryParams(sessionId, callSid)}`;
}

export function generateOzmoTutorialUrl(tutorial: Tutorial, sessionId: string, callSid?: string) {
    let tutorialUrl = tutorial.tutorialBaseUrl.length ? tutorial.tutorialBaseUrl : OZMO_BASE_URL;
    const tutorialUrlParts = tutorial.tutorialBaseUrl.split("?");
    if (tutorialUrlParts.length > 1) {
        tutorialUrl = `${tutorialUrlParts[0] + tutorial.answerUrl}?${tutorialUrlParts[1]}`;
    }
    return `${tutorialUrl}&${getUrlQueryParams(sessionId, callSid)}`;
}
