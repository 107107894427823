import type { FlexProps } from "@mantine/core";
import { Flex } from "@mantine/core";
import clsx from "clsx";
import classes from "./index.module.css";

export type PageContainerProps = React.HTMLProps<HTMLDivElement>;

export function PageContainer(props: React.HTMLProps<HTMLDivElement>) {
    return <div {...props} className={clsx(classes.pageContainer, props.className)} />;
}

export type ReusableMainContainerProps = FlexProps;

export function ReusableMainContainer(props: ReusableMainContainerProps) {
    return <Flex {...props} className={clsx(classes.reusableMainContainer, props.className)} />;
}
