import { useEffect } from "react";
import { Button, Title } from "@mantine/core";
import { useAuth } from "react-oidc-context";
import { showErrorNotification } from "../components/errors";
import { Header } from "../components/header";
import { PageContainer, ReusableMainContainer } from "../components/page-layout";
import { GetStartedAnimation } from "../components/svgs/GetStartedAnimation";
import { useLoggerWithContextStore } from "../state";
import classes from "./LandingPage.module.css";

function LeftContainer({ children }: React.PropsWithChildren) {
    return <div className={classes.leftContainer}>{children}</div>;
}

function RightContainer({ children }: React.PropsWithChildren) {
    return <div className={classes.rightContainer}>{children}</div>;
}

function MainContent({ children }: React.PropsWithChildren) {
    return <div className={classes.mainContent}>{children}</div>;
}

export function LandingPage() {
    const auth = useAuth();
    const { loggerWithContext } = useLoggerWithContextStore();
    const localLogger = loggerWithContext.child({ module: "LandingPage" });

    const onVerifyAuthentication = async () => {
        try {
            await auth.signinRedirect({ nonce: crypto.randomUUID() });
        } catch (err) {
            localLogger.error(err, "Auth error occured");
        }
    };

    useEffect(() => {
        if (auth.error) {
            localLogger.error(`Auth error: ${auth.error.message}`);
            showErrorNotification("AuthenticationError");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.error]);

    return (
        <PageContainer>
            <Header />
            <ReusableMainContainer direction="row" justify="center">
                <MainContent>
                    <LeftContainer>
                        <Title order={1} data-testid="description" fw="var(--mantine-font-weight-light)">
                            Here to help you solve faster
                        </Title>
                        <Button
                            data-testid="get-started-button"
                            onClick={() => void onVerifyAuthentication()}
                            size="lg"
                            variant="filled"
                        >
                            Get Started
                        </Button>
                    </LeftContainer>
                    <RightContainer>
                        <GetStartedAnimation />
                    </RightContainer>
                </MainContent>
            </ReusableMainContainer>
        </PageContainer>
    );
}
