import type { TimelineFeatures } from "@expert/features";
import { SUPPORTED_GAIA_PARTNERS, type SupportedPartners } from "./websocket";
import type { GaiaPayloadConfigs, Partner } from "@expert/shared-types";
import type { Logger } from "@expert/logging";

export const getExperimentName = ({
    isReactiveCallContextEnabled,
    isReactiveCallContextV2Enabled,
    isSalesFAQEnabled,
    lob,
}: Pick<TimelineFeatures, "isReactiveCallContextEnabled" | "isReactiveCallContextV2Enabled" | "isSalesFAQEnabled"> & {
    lob?: string;
}) => {
    // care/mobility workflows do not support other features at this time
    if (lob === "care" || lob === "mobility" || lob === "perks") return undefined;
    // gaia workflows support one experiment at a time - the first one that is enabled will be used
    if (isSalesFAQEnabled) return "sales-faq";
    if (isReactiveCallContextV2Enabled) return "call-context-v2";
    if (isReactiveCallContextEnabled) return "call-context";
};

export const getSupportedLineOfBusiness = ({ partner, lob }: { partner: Partner; lob?: string }) => {
    // verizon is the only partner that supports multiple lines of business at this time
    if (partner === "verizon") return lob;
    return undefined;
};

// if we do not explicitly support the partner, default to use the asurion workflow
// THIS IS A TEMPORARY HACK TO MAKE SURE TMO & OTHER SMALL PARTNERS DO NOT BREAK
// TODO: Work with gaia to add global workflow to support non-custom partners in a non-hacky way [EWS-1101]
export const getSupportedPartner = ({ partner, logger }: { partner: Partner; logger: Logger }) => {
    if (SUPPORTED_GAIA_PARTNERS.includes(partner)) return partner;
    logger.info({ partner }, `GAIA | Partner is not supported. Defaulting to asurion.`);
    return "asurion";
};

export const payloadConfigsByPartner: Record<
    "internal" | "external",
    Partial<Record<SupportedPartners, GaiaPayloadConfigs>>
> = {
    internal: {
        asurion: {
            appName: "expert-assist",
            autoCompleteAppName: "expert-assist-autocomplete",
            areExperimentsEnabled: true,
        },
        att: {
            appName: "expert-assist",
            autoCompleteAppName: "expert-assist-autocomplete",
            areExperimentsEnabled: true,
        },
        mcafee: {
            appName: "expert-assist",
            autoCompleteAppName: "expert-assist-autocomplete",
            areExperimentsEnabled: true,
        },
        verizon: {
            appName: "expert-assist",
            autoCompleteAppName: "expert-assist-autocomplete",
            areExperimentsEnabled: true,
        },
    },
    external: {
        mcafee: {
            appName: "expert-assist-ext",
            autoCompleteAppName: "expert-assist-autocomplete-ext",
            areExperimentsEnabled: false,
        },
    },
};
