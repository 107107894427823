import type { TMessage } from "@simplrsupport/headless-chat-web";
import type { UiMetadata } from "../logic-builder/types";

export const defaultUiMetadata: UiMetadata = {
    flowName: "Guide",
    toolboxLabel: "Launch guide", // TODO: update defaults [EWS-1094](https://solutonashville.atlassian.net/browse/EWS-1094)
    closeConfirmation: {
        title: "Are you sure you’d like to close?",
        options: ["Didn’t work", "Other"],
    },
    restartConfirmation: { title: "Restart the flow?" },
};

export const defaultMessage = {
    action: "SEND_MESSAGE",
    type: "TEXT",
    quickInput: {
        type: "BUTTONS",
        options: [
            {
                value: "Start activation",
                label: "Start activation",
            },
        ],
    },
    awaitInput: {
        isCombinedWithSendMessageNode: true,
    },
    isFirstStep: true,
    stepId: "StartDeviceActivation",
    text: "Use this step-by-step guide to make activations easy",
} as unknown as TMessage; // cast to unknown first, the headless-web-chat type for the type field is wrong
