import { useMemo } from "react";
import { trackFullStoryEvent } from "@expert/monitoring";
import type { Logger } from "@expert/logging";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import type { OrchestratorInvokeMessagePayload } from "@expert/shared-types";
import { type TimelineFeatures, useFeatures } from "@expert/features";
import {
    getExperimentName,
    getSupportedLineOfBusiness,
    getSupportedPartner,
    payloadConfigsByPartner,
    useGaiaStore,
    useGaiaWebsocket,
} from "@expert/gaia";
import { addFailureMessage, addMessage, useTimelineStore } from "../state";
import type { SendMessageProps } from "../shared-types";

export const useSendExpertAssistMessage = (loggerProp: Logger, isExternal: boolean) => {
    const websocketObj = useGaiaWebsocket();
    const { subscriptionStatus } = useGaiaStore();
    const { dispatcher } = useReactAnalytics();
    const {
        features: { isReactiveCallContextEnabled, isReactiveCallContextV2Enabled, isSalesFAQEnabled },
    } = useFeatures<TimelineFeatures>();
    const { callSid, expertId, lob, partner, sessionId } = useTimelineStore();

    const logger = useMemo(() => loggerProp.child({ module: "useSendExpertAssistMessage" }), [loggerProp]);

    const sendMessage = ({ id, metadata, text, type, source }: SendMessageProps) => {
        const messageId = id ?? crypto.randomUUID();
        const correlationId = crypto.randomUUID();
        const gaiaPartner = getSupportedPartner({ partner, logger });
        const { areExperimentsEnabled, appName } =
            payloadConfigsByPartner[isExternal ? "external" : "internal"][gaiaPartner] ?? {};
        const experimentName = areExperimentsEnabled
            ? getExperimentName({
                  isReactiveCallContextEnabled,
                  isReactiveCallContextV2Enabled,
                  isSalesFAQEnabled,
                  lob,
              })
            : undefined;

        const lineOfBusiness = getSupportedLineOfBusiness({ partner, lob });
        const payload: OrchestratorInvokeMessagePayload = {
            action: "orchestrator-invoke",
            appName: appName ?? "expert-assist",
            message: text,
            messageId,
            messageType: "Search",
            partner: gaiaPartner,
            sessionId: callSid ?? sessionId,
            sessionGroupId: sessionId,
            userId: expertId,
            userType: "expert",
            correlationId,
            experimentName,
            lineOfBusiness,
        };

        const messageText = metadata?.helpMeNowText ?? text;
        addMessage({ id: messageId, text: messageText, type, isUnread: false, metadata, status: "active" });

        const analyticsInfo = {
            expertAssistAppName: "expert-assist",
            expertAssistExperimentName: experimentName,
            gaiaPartner,
            messageId,
            messageType: type,
            source,
        };

        if (!websocketObj) {
            logger.error({ payload, websocketObj }, "There is no websocket obj");
            const failureMessage = addFailureMessage("connectionFailure");
            void dispatcher.dispatchBusinessEvent("ErrorMessageReceived", {
                senderType: "system",
                ...analyticsInfo,
                messageType: failureMessage.type,
                messageId: failureMessage.id,
                message: failureMessage.text,
            });
            return;
        }

        if (subscriptionStatus[sessionId] !== "subscribed") {
            // TODO: Currently, this attempts to resubscribe + sends error message
            // Should we attempt to resubscribe + await + resend message?
            void websocketObj.subscribeSessionToGaia({
                sessionId,
                callSid,
                partner,
                sendJsonMessage: websocketObj.sendJsonMessage,
                logger,
            });
            logger.warn("Expert session not subscribed, subscribing now");
            const failureMessage = addFailureMessage("connectionFailure");
            void dispatcher.dispatchBusinessEvent("ErrorMessageReceived", {
                senderType: "system",
                ...analyticsInfo,
                messageType: failureMessage.type,
                messageId: failureMessage.id,
                message: failureMessage.text,
            });
            return;
        }
        websocketObj.sendJsonMessage(payload);
        void dispatcher.dispatchBusinessEvent("MessageSent", {
            senderType: "expert",
            ...analyticsInfo,
        });
        logger.info({ payload }, "Message sent to gaia");
        trackFullStoryEvent("ExpertAssistMessageSent", {
            sessionId,
            callSid,
            ...analyticsInfo,
        });
    };
    return sendMessage;
};
