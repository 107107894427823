import initRedactor from "@soluto-private/soluto-redact-pii";
import { userPatterns } from "./userPatterns";

const init = async () => await initRedactor({ userPatterns });

/** https://github.com/Soluto-Private/soluto-pii-redactor?tab=readme-ov-file#api
 * @returns text with PII scrubbed */
export const removePii = async (text: string) => {
    const redactor: Awaited<ReturnType<typeof initRedactor>> = await init();
    const { redactedText } = redactor.redact(text);
    return redactedText;
};
