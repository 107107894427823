import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { defaultUiMetadata } from "./defaults";
import type { GuideOption, UiMetadata } from "../logic-builder";

export interface SessionMetadata {
    [key: string]: unknown;
}

export interface GuideStore {
    currentGuideName?: string;
    setCurrentGuideName: (currentGuideName?: string) => void;
    currentStepId?: string;
    setCurrentStepId: (currentStepId?: string) => void;
    completedSteps?: string;
    setCompletedSteps: (completedSteps?: string) => void;
    completedStepCount: number;
    setCompletedStepCount: (count: number) => void;

    disableAutomaticOpen: boolean;
    setDisableAutomaticOpen: (value: boolean) => void;

    errorStateSessionId?: string;
    setErrorStateSessionId: (sessionId?: string) => void;
    isErrorState: boolean;
    setIsErrorState: (value: boolean) => void;

    sessionMetadata: SessionMetadata;
    setSessionMetadata: (sessionMetadata: SessionMetadata) => void;

    uiMetadata: UiMetadata;
    setUiMetadata: (uiMetadata: UiMetadata) => void;

    selectedOptions: Record<string, GuideOption>;
    setSelectedOptions: (selectedOptions: Record<string, GuideOption>) => void;

    resetGuideErrorStates: () => void;
}

export const useGuideStore = create<GuideStore>()(
    persist(
        immer(
            devtools(
                (set) => ({
                    currentGuideName: undefined,
                    setCurrentGuideName: (currentGuideName?: string) => {
                        set(
                            (state) => {
                                state.currentGuideName = currentGuideName;
                            },
                            false,
                            "setCurrentGuideName",
                        );
                    },
                    currentStepId: undefined,
                    setCurrentStepId: (currentStepId?: string) => {
                        set(
                            (state) => {
                                state.currentStepId = currentStepId;
                            },
                            false,
                            "setCurrentStepId",
                        );
                    },
                    completedSteps: "",
                    setCompletedSteps: (completedSteps?: string) => {
                        set(
                            (state) => {
                                state.completedSteps = completedSteps;
                            },
                            false,
                            "setCompletedSteps",
                        );
                    },
                    completedStepCount: 0,
                    setCompletedStepCount: (count: number) => {
                        set(
                            (state) => {
                                state.completedStepCount = count;
                            },
                            false,
                            "setCompletedStepCount",
                        );
                    },
                    disableAutomaticOpen: false,
                    setDisableAutomaticOpen: (value: boolean) => {
                        set(
                            (state) => {
                                state.disableAutomaticOpen = value;
                            },
                            false,
                            "setDisableAutomaticOpen",
                        );
                    },
                    errorStateSessionId: undefined,
                    setErrorStateSessionId: (sessionId?: string) => {
                        set(
                            (state) => {
                                if (!sessionId) {
                                    state.errorStateSessionId = undefined;
                                } else if (state.errorStateSessionId) {
                                    state.errorStateSessionId = `${state.errorStateSessionId}_RESTARTED`;
                                } else if (sessionId) {
                                    state.errorStateSessionId = `${sessionId}_RESTARTED`;
                                }
                            },
                            false,
                            "setErrorStateSessionId",
                        );
                    },
                    isErrorState: false,
                    setIsErrorState: (value: boolean) => {
                        set(
                            (state) => {
                                state.isErrorState = value;
                            },
                            false,
                            "setIsErrorState",
                        );
                    },
                    sessionMetadata: {},
                    setSessionMetadata: (sessionMetadata: SessionMetadata) => {
                        set(
                            (state) => {
                                state.sessionMetadata = sessionMetadata;
                            },
                            false,
                            "setSessionMetadata",
                        );
                    },
                    selectedOptions: {},
                    setSelectedOptions: (selectedOptions: Record<string, GuideOption>) => {
                        set(
                            (state) => {
                                state.selectedOptions = selectedOptions;
                            },
                            false,
                            "setCurrentGuideName",
                        );
                    },
                    uiMetadata: defaultUiMetadata,
                    setUiMetadata: (uiMetadata: UiMetadata) => {
                        set(
                            (state) => {
                                state.uiMetadata = uiMetadata;
                            },
                            false,
                            "setUiMetadata",
                        );
                    },
                    resetGuideErrorStates: () => {
                        set(
                            (state) => {
                                state.isErrorState = false;
                                state.errorStateSessionId = undefined;
                            },
                            false,
                            "resetGuideErrorStates",
                        );
                    },
                }),
                {
                    enabled: import.meta.env.VITE_ENV_TYPE !== "production",
                    store: "simplr-guide-store",
                    name: "simplr-guide-store",
                },
            ),
        ),
        { name: "guide-store", skipHydration: true },
    ),
);
