import { getLogger } from "@expert/logging";
import type { LiteralUnion } from "type-fest";

export interface MetricsToServerProps {
    accessToken: string;
    collectorUrl: string;
    metricsNamespace?: string;
}

export type CommonMetricNames =
    | "Start"
    | "Success"
    | "Failure"
    | "Duration"
    | "Event"
    | "Error"
    | "Retry"
    | "Count"
    | "Exhausted";
export type MetricName = LiteralUnion<CommonMetricNames, string>;

export interface ExWoMetricData {
    metrics: {
        /**
         * Name should be as generic as possible, e.g. 'responseTime', 'failure', 'success'
         * Preferably to use predefined names from 'CommonMetricNames` type.
         */
        name: MetricName;
        value: number;
        unit?: string;
    }[];
    /**
     * Dimensions for the metrics, e.g. { 'service': 'CallControls' } or { action: 'getUserStatus' }
     */
    dimensions?: Record<string, string>;
    /**
     * Timestamp for the metrics, defaults to now
     */
    timestamp?: Date | number;
    /**
     * Namespace for the metrics, defaults to 'ExpertWorkspace'
     */
    namespace?: string;
}

const logger = getLogger({
    module: "getChannelShareData",
});

let metricsToServerProps: MetricsToServerProps | undefined;

export function initMetrics(props: MetricsToServerProps) {
    metricsToServerProps = props;
}

export async function sendMetrics(metricsData: ExWoMetricData[], sendProps?: MetricsToServerProps) {
    const props = { ...metricsToServerProps, ...sendProps };
    if (!props.accessToken || !props.collectorUrl) {
        logger.warn({ metricsData }, "Cannot send metrics to the backend");
        return;
    }
    const metricsDatWithDefaults: ExWoMetricData[] = metricsData.map((metric) => ({
        namespace: props.metricsNamespace,
        ...metric,
    }));
    await fetch(props.collectorUrl, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.accessToken}`,
        },
        keepalive: true,
        body: JSON.stringify(metricsDatWithDefaults),
    });
}
