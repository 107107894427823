import type { ContextProps } from "@expert/features";
import type { Logger } from "@expert/logging";
import { AnalyticsProvider } from "@soluto-private/eventualize-react";
import { useSetDraggableBounds } from "@soluto-private/expert-workspace-notepad";
import { Timeline } from "@soluto-private/expert-workspace-timeline";
import React, { useMemo, useRef } from "react";
import { useTimelineSubscriptions } from "../../initializers";
import { useExpertAssistStore, useLoggerWithContextStore } from "../../state";
import { envName, timelineFeatures } from "../../utils";

function SetupSubscriptions({ loggerWithContext }: { loggerWithContext: Logger }): React.JSX.Element {
    useTimelineSubscriptions(loggerWithContext);

    return <></>;
}
export function StandaloneTimeline() {
    const { callSid, partner, expertProfile, sessionId, lob } = useExpertAssistStore();
    const { loggerWithContext } = useLoggerWithContextStore();
    const containerRef = useRef<HTMLDivElement>(null);
    useSetDraggableBounds(containerRef);
    const expertId = expertProfile?.id ?? expertProfile.email; /*e2e users don't have ids */

    const featuresContext = useMemo(() => {
        return {
            appName: "expert-assist-standalone",
            employeeId: expertId,
            environment: envName,
            channel: "voice",
            lob,
            partner,
            source: "expert-assist",
        } as ContextProps;
    }, [expertId, lob, partner]);

    return (
        <AnalyticsProvider
            dispatcher={(dispatcher) =>
                dispatcher
                    .withIdentities({
                        ...(callSid && { CallSid: callSid }),
                        ...(expertProfile.id && { ExpertId: expertProfile.id }),
                        ...(sessionId && { SessionId: sessionId }),
                    })
                    .withExtra({ tool: "expert-assist", partner, lob })
            }
        >
            <Timeline
                ref={containerRef}
                callSid={callSid}
                defaultFeatures={timelineFeatures}
                expertId={expertId}
                expertName={expertProfile.firstName || expertProfile.lastName}
                featuresContext={featuresContext}
                logger={loggerWithContext}
                partner={partner}
                lob={lob}
                sessionId={sessionId}
                SubscriptionsWithFeatures={<SetupSubscriptions loggerWithContext={loggerWithContext} />}
            />
        </AnalyticsProvider>
    );
}
