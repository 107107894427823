import { HelpMeNowButton, NewMessageButton } from "@expert/proactive-notifications";
import { Group } from "@mantine/core";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { useEffect, useMemo, useState } from "react";
import { type TimelineFeatures, useFeatures } from "@expert/features";
import { useSendExpertAssistMessage } from "../../hooks";
import type { ExpertAssistMessage } from "../../shared-types";
import { useTimelineStore } from "../../state";
import { isFromBot, useAutoscroll } from "../../utils";
import { List, TimelineListWrapper } from "./elements";
import { generateTimelineItems } from "./generateTimelineItems";
import type { TimelineListProps } from "./types";
import { useScrollHandler } from "./useScrollHandler";
import { trackFullStoryEvent } from "@expert/monitoring";

export function TimelineList({ logger, sessionId, scrollRef, isExternal = false }: TimelineListProps) {
    const { dispatcher } = useReactAnalytics();
    const { callSid, messages, markAllMessagesAsRead } = useTimelineStore();
    const {
        features: { isHelpMeNowEnabled, helpMeNowQuestion },
    } = useFeatures<TimelineFeatures>();
    const { start: startAutoscroll } = useAutoscroll(scrollRef);
    const { mergedRef, isScrollAtBottom } = useScrollHandler({ scrollRef, messages: messages.length });
    const [shouldShowNewMessageButton, setShouldShowNewMessageButton] = useState(false);
    const sendMessage = useSendExpertAssistMessage(logger, isExternal);

    const timelineItems = useMemo(
        () =>
            generateTimelineItems({
                isExternal,
                logger,
                messages,
                scrollRef,
            }),
        [messages, scrollRef, logger, isExternal],
    );

    const unreadMessages = useMemo(() => messages.filter((message) => message.isUnread).length, [messages]);
    const mostRecentMessage = messages[messages.length - 1] as ExpertAssistMessage | undefined;
    const isLoadingBotMessage = !isFromBot(mostRecentMessage?.type ?? "Default");

    const onNewMessageClick = () => {
        logger.info("New message button clicked");
        void dispatcher.dispatchUserAction("Click", "NewMessage", { sessionId });
        startAutoscroll();
    };

    const onHelpMeNowClick = () => {
        logger.info("Help me now button clicked");
        const type = "Expert";
        const button = "Quick solve";
        const messageId = crypto.randomUUID();
        void dispatcher.dispatchBusinessEvent("Click_HelpMeNow", { button, sessionId, messageId });
        trackFullStoryEvent("Click_HelpMeNow", {
            button,
            sessionId,
            callSid,
            messageId,
        });
        sendMessage({
            text: helpMeNowQuestion,
            type,
            id: messageId,
            metadata: { helpMeNowText: button },
            source: "HelpMeNow",
        });
    };

    useEffect(() => {
        setShouldShowNewMessageButton(unreadMessages > 0 && !isScrollAtBottom);
        if (isScrollAtBottom && unreadMessages > 0) {
            markAllMessagesAsRead();
        }
    }, [unreadMessages, isScrollAtBottom, markAllMessagesAsRead]);

    // reset the animation for session resets
    useEffect(() => {
        const animatedElement = document.getElementById("timeline-list-wrapper");
        if (animatedElement) {
            animatedElement.style.animation = "none";
            animatedElement.style.animation = "ease-out 1s slide-in 1";
        }
    }, [sessionId]);

    return (
        <TimelineListWrapper data-testid="timeline-list-wrapper" mergedRef={mergedRef}>
            <List
                isShowingActionButtons={shouldShowNewMessageButton || (!!callSid && isHelpMeNowEnabled)}
                data-testid="timeline-list"
            >
                {timelineItems}
            </List>
            <Group pos="absolute" align="center" dir="row" justify="justify-content" bottom="6.725rem">
                <NewMessageButton
                    shouldShow={shouldShowNewMessageButton}
                    num={unreadMessages}
                    onClick={onNewMessageClick}
                />
                <HelpMeNowButton
                    shouldShow={!!callSid && isHelpMeNowEnabled}
                    isLoading={isLoadingBotMessage}
                    onClick={onHelpMeNowClick}
                />
            </Group>
        </TimelineListWrapper>
    );
}
