import { addMessage, getLastMessage } from "../state";
import { getDefaultMessage } from "./getDefaultMessage";

export const addFirstOpenMessageIfNeeded = (expertName: string) => {
    const lastMessage = getLastMessage();
    if (!lastMessage) {
        const firstOpenMessage = getDefaultMessage("firstOpen");
        addMessage({
            ...firstOpenMessage,
            text: firstOpenMessage.text.replace("$EXPERT_NAME", expertName),
            id: `firstOpen_${crypto.randomUUID()}`,
        });
    }
};
