import { customCleanEnv, str, url, bool } from "envalid";
import type { LevelOrString } from "pino";

interface EnvVars {
    envName: string;
    baseUrl: string;
    appName: string;
    commitHash: string;
    gaiaWebsocketUrl: string;
    oidcClientId: string;
    ssoStorageKey: string;
    appsyncGraphqlUrl: string;
    appsyncRegion: string;
    fullstoryOrgId: string;
    isDynatraceEnabled: boolean;
    dynatraceUrl: string;
    isAppcuesEnabled: boolean;
    appcuesUrl: string;
    isProd: boolean;
    contentful: {
        accessToken: string;
        space: string;
        host: string;
    };
    expertFeedbackUrl: string;
    logCollectorUrl: `${string}/log`;
    logLevel: {
        api: LevelOrString;
        console: LevelOrString;
    };
}

export const env = customCleanEnv(
    import.meta.env,
    {
        COMMIT_HASH: str(),
        VITE_ENV_NAME: str(),
        VITE_APP_NAME: str(),
        VITE_GAIA_WEBSOCKET_URL: url(),
        VITE_OIDC_SSO_CLIENT_ID: str(),
        VITE_SSO_STORAGE_KEY: url(),
        VITE_APPSYNC_GRAPHQL_URL: url(),
        VITE_APPSYNC_REGION: str(),
        VITE_FULLSTORY_ORG_ID: str(),
        VITE_DYNATRACE_ENABLED: bool(),
        VITE_DYNATRACE_URL: url(),
        VITE_APPCUES_ENABLED: bool(),
        VITE_APPCUES_URL: str(),
        VITE_CONTENTFUL_ACCESS_TOKEN: str(),
        VITE_CONTENTFUL_SPACE: str(),
        VITE_API_BASE_URL: url(),
        VITE_CONTENTFUL_HOST: str(),
        VITE_LOG_LEVEL_CONSOLE: str({ default: "trace" }),
        VITE_LOG_LEVEL_API: str({ default: "warn" }),
    },
    (envProp): EnvVars => ({
        envName: envProp.VITE_ENV_NAME ?? "development",
        baseUrl: envProp.VITE_API_BASE_URL,
        appName: envProp.VITE_APP_NAME,
        commitHash: envProp.COMMIT_HASH,
        gaiaWebsocketUrl: envProp.VITE_GAIA_WEBSOCKET_URL,
        oidcClientId: envProp.VITE_OIDC_SSO_CLIENT_ID,
        ssoStorageKey: envProp.VITE_SSO_STORAGE_KEY,
        appsyncGraphqlUrl: envProp.VITE_APPSYNC_GRAPHQL_URL,
        appsyncRegion: envProp.VITE_APPSYNC_REGION,
        fullstoryOrgId: envProp.VITE_FULLSTORY_ORG_ID,
        isDynatraceEnabled: envProp.VITE_DYNATRACE_ENABLED,
        dynatraceUrl: envProp.VITE_DYNATRACE_URL,
        isAppcuesEnabled: envProp.VITE_APPCUES_ENABLED,
        appcuesUrl: envProp.VITE_APPCUES_URL,
        isProd: envProp.VITE_ENV_NAME === "prod",
        contentful: {
            accessToken: envProp.VITE_CONTENTFUL_ACCESS_TOKEN,
            space: envProp.VITE_CONTENTFUL_SPACE,
            host: envProp.VITE_CONTENTFUL_HOST,
        },
        expertFeedbackUrl: `${envProp.VITE_API_BASE_URL}/api/v1/feedback/expert`,
        logCollectorUrl: `${envProp.VITE_API_BASE_URL}/log`,
        logLevel: {
            api: envProp.VITE_LOG_LEVEL_API,
            console: envProp.VITE_LOG_LEVEL_CONSOLE,
        },
    }),
);
