import { Stack } from "@mantine/core";
import type { MessageType } from "@expert/shared-types";
import classes from "./MessageBubble.module.css";

// container that holds the message bubble and any buttons that are supposed to be displayed below it
export function MessageBubbleWrapper({
    isBotListening,
    type,
    children,
}: {
    isBotListening: boolean;
    isExpanded: boolean;
    type: MessageType;
    children: React.ReactNode;
}): JSX.Element {
    const messageWrapperClass = classes.messageBubbleWrapper;
    const botListeningClass = isBotListening ? `${messageWrapperClass} ${classes.botListening}` : messageWrapperClass;
    const className =
        type === "SessionSummary" ? `${botListeningClass} ${classes.sessionSummaryContainer}` : botListeningClass;

    return <Stack className={className}>{children}</Stack>;
}
