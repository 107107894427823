import { reactAnalytics } from "@soluto-private/eventualize-react";
import type { Logger } from "@expert/logging";
import { processMessage, setDeviceInfo } from "@expert/solve-tools";
import { trackAppcuesEvent, trackFullStoryEvent } from "@expert/monitoring";
import type { GaiaBotMessage, ReactiveMessagePayload } from "@expert/shared-types";
import { type FailureReason, addFailureMessage, addMessage } from "../state";
import type { ExpertAssistMessage } from "../shared-types";

export const addErrorMessage = (reason: FailureReason) => {
    const message = addFailureMessage(reason);
    void reactAnalytics.dispatcher.dispatchBusinessEvent("ErrorMessageReceived", {
        senderType: "system",
        messageType: message.type,
        messageId: message.id,
        message: message.text,
    });
};

export const handleIncomingMessage = (msg: GaiaBotMessage, logger: Logger) => {
    const { sessionId, callSid, formattedMessage, analytics } = parseExpertAssistMessage(msg);
    if (sessionId && formattedMessage) {
        addMessage(formattedMessage);
        const reactiveMessage = msg as ReactiveMessagePayload;
        if (reactiveMessage.externalDevices) setDeviceInfo(reactiveMessage.externalDevices, logger);
        void reactAnalytics.dispatcher
            .withIdentities({
                ...(callSid && { CallSid: callSid }),
                ...(sessionId && { SessionId: sessionId }),
            })
            .dispatchBusinessEvent("MessageReceived", {
                senderType: "bot",
                messageType: formattedMessage.type,
                messageId: formattedMessage.id,
                ...analytics,
            });
        trackFullStoryEvent("ExpertAssistMessageReceived", {
            sessionId,
            callSid,
            messageType: formattedMessage.type,
            ...analytics,
        });
        trackAppcuesEvent("ExpertAssistMessageReceived", { messageType: formattedMessage.type, ...analytics });
    } else {
        addErrorMessage("botFailure");
        logger.warn(
            { sessionId: sessionId ?? "undefined", hasFormattedMessage: !!formattedMessage, fullMessage: msg },
            "GAIA | Unable to handling incoming message - missing expected value",
        );
    }
};

const parseExpertAssistMessage = (msg: GaiaBotMessage) => {
    let formattedMessage: ExpertAssistMessage | undefined;
    let analytics: Record<string, unknown> | undefined;

    const sessionId = msg.sessionGroupId;
    const callSid = msg.sessionId?.startsWith("C") ? msg.sessionId : undefined; // TODO: This only works for twilio call sids

    if ("response" in msg) {
        const messageId = crypto.randomUUID();
        const { response, analytics: updatedAnalytics } = processMessage({ ...msg, messageId });
        analytics = updatedAnalytics;
        formattedMessage = {
            id: messageId,
            text: response,
            type: "Search",
            isUnread: true,
            status: "active",
            timestamp: msg.responseTimestamp,
        };
    } else if (msg.suggestion.messageType === "Proactive") {
        formattedMessage = {
            id: msg.suggestion.messageId,
            text: msg.suggestion.message,
            type: msg.suggestion.messageType,
            isUnread: true,
            status: "active",
            timestamp: msg.suggestion.outputTimestamp,
            tag: "Solve suggestion",
        };
        analytics = { messageIntent: msg.suggestion.metadata?.intentClassification ?? "unknown" };
    }

    return { formattedMessage, sessionId, callSid, analytics };
};
