import { create } from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import type { Subscription } from "../graphql";
import { fallbackPartner, fallbackLob } from "../utils";
import type { ExpertProfile, Subscriptions } from "./types";
import { getEaLogger } from "./loggerStore";
import type { Partner } from "@expert/shared-types";

const localLogger = getEaLogger().child({ module: "store" });
interface SessionDetails {
    sessionId: string;
    callSid?: string;
    partner?: Partner;
    lob?: string;
}
export interface ExpertAssistStore {
    sessionId: string;
    callSid?: string;
    partner: Partner;
    setPartner: (partner: Partner) => void;
    setSessionDetails: (sessionDetails: SessionDetails) => void;

    lob: string;
    setLob: (lob: string) => void;

    expertProfile: ExpertProfile;
    setExpertProfile: (profile: ExpertProfile) => void;

    subscriptions: Subscriptions;
    setSubscription: (id: keyof Subscriptions, subscription?: Subscription) => void;

    token?: string;
    setToken: (token: string) => void;
}

export const useExpertAssistStore = create<ExpertAssistStore>()(
    subscribeWithSelector(
        immer(
            devtools((set) => ({
                sessionId: "",
                expertProfile: {
                    firstName: "",
                    lastName: "",
                    id: "",
                    email: "",
                },
                setExpertProfile: (profile: ExpertProfile) => {
                    set((state) => {
                        localLogger.debug(profile, "expertProfile");
                        state.expertProfile = profile;
                    });
                },
                callSid: undefined,
                partner: fallbackPartner,
                setPartner: (partner: Partner) => {
                    set(
                        (state) => {
                            state.partner = partner;
                        },
                        false,
                        "setPartner",
                    );
                },
                lob: fallbackLob,
                setLob: (lob: string) => {
                    set(
                        (state) => {
                            state.lob = lob;
                        },
                        false,
                        "setLob",
                    );
                },
                setSessionDetails: ({ sessionId, callSid, partner, lob }) => {
                    set((state) => {
                        state.sessionId = sessionId;
                        state.callSid = callSid;
                        state.partner = partner ?? fallbackPartner;
                        state.lob = lob ?? fallbackLob;
                    });
                },
                subscriptions: {},
                setSubscription: (id: keyof Subscriptions, subscription?: Subscription) => {
                    set((state) => {
                        state.subscriptions[id] = subscription;
                    });
                },
                token: undefined,
                setToken: (token: string) => {
                    set((state) => {
                        state.token = token;
                    });
                },
            })),
        ),
    ),
);
