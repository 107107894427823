import { List } from "@mantine/core";
import type { MarkdownToJSX } from "markdown-to-jsx";
export type MarkdownOverrides = MarkdownToJSX.Overrides;
export const listOverride: MarkdownOverrides = {
    ol: {
        component: List,
        props: {
            type: "ordered",
            size: "sm",
            withPadding: true,
            style: {
                listStylePosition: "outside",
                marginLeft: "1.5rem",
            },
        },
    },
    ul: {
        component: List,
        props: {
            type: "unordered",
            listStyleType: "disc",
            size: "sm",
            withPadding: true,
            style: {
                listStylePosition: "outside",
                marginLeft: "0.5rem",
            },
        },
    },
    li: {
        component: List.Item,
    },
};
