import { type SyntheticEvent, forwardRef } from "react";
import { useUncontrolled } from "@mantine/hooks";
import type { ComboboxItem, ComboboxStringData, TextareaProps } from "@mantine/core";
import { Combobox, getParsedComboboxData, getOptionsLockup, Textarea, useCombobox } from "@mantine/core";
import classes from "./MessageInput.module.css";

export interface AutocompleteProps extends TextareaProps {
    data: ComboboxStringData;
    dropdownOpened: boolean;
    handleSendInput: (event: SyntheticEvent) => void;
    isSendDisabled: boolean;
    isTextDisabled: boolean;
    onOptionSubmit: (option: string) => void;
    onTextareaChange: (value: string) => void;
}

export const Autocomplete = forwardRef<HTMLTextAreaElement, AutocompleteProps>(function Autocomplete(
    {
        className,
        data,
        dropdownOpened,
        isSendDisabled,
        isTextDisabled,
        handleSendInput,
        leftSection,
        onBlur,
        onClick,
        onKeyDown,
        onOptionSubmit,
        onTextareaChange,
        rightSection,
        value,
        ...rest
    }: AutocompleteProps,
    ref,
): JSX.Element {
    const parsedData = getParsedComboboxData(data) as ComboboxItem[];
    const optionsLockup = getOptionsLockup(parsedData);

    const [val, setVal] = useUncontrolled({
        value: value as string,
        onChange: onTextareaChange,
    });

    const combobox = useCombobox({
        opened: dropdownOpened,
        onDropdownClose: () => {
            combobox.resetSelectedOption();
        },
    });

    const options = parsedData.map((item) => {
        const option = item.label;
        return (
            <Combobox.Option key={option} value={option}>
                {option}
            </Combobox.Option>
        );
    });

    return (
        <Combobox
            classNames={{ options: classes.options }}
            onOptionSubmit={(option) => {
                onOptionSubmit(option);
                setVal(optionsLockup[option].label);
                combobox.closeDropdown();
            }}
            position="top-start"
            size="sm"
            store={combobox}
            width="fill"
            zIndex="var(--mantine-priority-highest)"
        >
            <Combobox.Target>
                <Textarea
                    autosize
                    disabled={isTextDisabled}
                    className={className}
                    classNames={{ input: classes.input }}
                    leftSection={leftSection}
                    leftSectionWidth="4.25rem"
                    maxRows={5}
                    minRows={1}
                    onBlur={(event) => {
                        combobox.closeDropdown();
                        onBlur?.(event);
                    }}
                    onChange={(event) => {
                        setVal(event.currentTarget.value);
                        combobox.openDropdown();
                        onTextareaChange(event.currentTarget.value);
                    }}
                    onClick={(event) => {
                        combobox.openDropdown();
                        onClick?.(event);
                    }}
                    onFocus={() => {
                        combobox.openDropdown();
                    }}
                    onKeyDown={(event) => {
                        onKeyDown?.(event);
                        if (event.key === "Enter" && !event.shiftKey && !isSendDisabled) {
                            if (combobox.getSelectedOptionIndex() === -1) {
                                handleSendInput(event);
                            }
                        }
                    }}
                    placeholder="Ask Assist or type / to open the toolbox"
                    radius="xl"
                    ref={ref}
                    rightSection={rightSection}
                    rightSectionWidth={64}
                    size="xl"
                    style={{ width: "100%", input: { fz: "sm" } }}
                    value={val}
                    variant="filled"
                    {...rest}
                />
            </Combobox.Target>
            <Combobox.Dropdown>
                <Combobox.Options>{options}</Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
});
