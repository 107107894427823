import type { ExpertAssistMessage, SessionSummaryMetadata } from "../shared-types";
import { useTimelineStore } from "./timelineStore";
import { type FailureReason, failureMessages } from "./failureMessages";
import { type SeparatorProps, getSeparatorLabel } from "./separatorMessages";

/**
 * This is intended to set the default messages available to the timeline in state
 * Instead of just using a plain hardcoded list, this allows us to try to pull a
 * list from contentful and use this to store it for use in the timeline
 */
export const setDefaultMessages = (defaultMessages: ExpertAssistMessage[]) => {
    useTimelineStore.getState().setDefaultMessages(defaultMessages);
};
/**
 * This is intended to add regular message to the timeline
 * Please use `addFailureMessage` for specific failures
 */
export const addMessage = (message: ExpertAssistMessage) => {
    useTimelineStore.getState().addMessage(message);
};

export const addMessages = (messages: ExpertAssistMessage[]) => {
    useTimelineStore.getState().addMessages(messages);
};

/**
 * This is intended to add failure message to the timeline
 * Return the ExpertAssistMessage for the added message
 */
export const addFailureMessage = (failure: FailureReason): ExpertAssistMessage => {
    const message: ExpertAssistMessage = {
        ...failureMessages[failure],
        id: `botFailureMessage_${crypto.randomUUID()}`,
        isUnread: true,
        status: "active",
    };
    useTimelineStore.getState().addMessage(message);
    return message;
};

export const addSeparatorMessage = ({ text, timestamp, callDuration }: SeparatorProps) => {
    const message: ExpertAssistMessage = {
        type: "Separator",
        id: `separator_${crypto.randomUUID()}`,
        text: getSeparatorLabel({ text, timestamp, callDuration }),
        timestamp,
        callDuration,
        isUnread: false,
        status: "active",
    };
    useTimelineStore.getState().addMessage(message);
};

export const addSessionSummaryMessage = ({
    header,
    summary,
    timestamp,
    resolutionStatus,
    sessionType,
}: SessionSummaryMetadata) => {
    const { addMessage } = useTimelineStore.getState();
    const message: ExpertAssistMessage = {
        id: `sessionSummary_${crypto.randomUUID()}`,
        isUnread: false,
        text: summary,
        type: "SessionSummary",
        status: "active",
        metadata: {
            sessionSummary: {
                header,
                summary,
                timestamp,
                resolutionStatus,
                sessionType,
            },
        },
    };
    addMessage(message);
};
