import { useQuery } from "@tanstack/react-query";
import { useContentfulClient } from "../ContentfulClientProvider";
import { getPersonalizeCoverageExamples } from "../api";
import { getLogger } from "@expert/logging";
import type { Partner } from "@expert/shared-types";

const logger = getLogger({
    module: "personalize-coverage",
    supportTeam: "sales",
});
export const usePersonalizeCoverage = (partner: Partner) => {
    const contentfulClient = useContentfulClient();
    const { data } = useQuery({
        queryKey: [`personalize-coverage-${partner}`],
        queryFn: async () => await getPersonalizeCoverageExamples(contentfulClient, partner),
    });
    if (!data) {
        logger.info({ partner }, "Failed to find personalize coverage examples");
        return [];
    }
    return data;
};
