import type { MarkdownOverrides } from "../../formatting/overrides";
import type { DividerProps, TextProps } from "@mantine/core";
import { Divider, List, Text } from "@mantine/core";

function ParagraphOverride(props: TextProps) {
    return <Text size="sm" pt="xs" pb="xs" {...props} />;
}
function SubtextOverride(props: TextProps) {
    return <Text size="xs" {...props} />;
}
function DividerOverride(props: DividerProps) {
    return <Divider color="primary.4" w="80%" mt="xs" mb="xs" {...props} />;
}

const listOverride: MarkdownOverrides = {
    ol: {
        component: List,
        props: {
            type: "ordered",
            size: "xs",
            spacing: "3xs",
            withPadding: true,
            style: {
                listStylePosition: "outside",
                marginLeft: "sm",
                marginBottom: "3xs",
            },
        },
    },
    ul: {
        component: List,
        props: {
            type: "unordered",
            listStyleType: "disc",
            size: "xs",
            spacing: "3xs",
            withPadding: true,
            style: {
                listStylePosition: "outside",
                marginLeft: "sm",
                marginBottom: "3xs",
            },
        },
    },
    li: {
        component: List.Item,
    },
};

export const markdownOverrides = {
    ...listOverride,
    Subtext: { component: SubtextOverride },
    subtext: { component: SubtextOverride },
    hr: { component: DividerOverride },
    p: { component: ParagraphOverride },
};
