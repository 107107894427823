import { type LevelOrString, type Logger, pino } from "pino";
import { createWrite } from "./utils/pretty";
import { send } from "./utils/sendToBackend";

let rootLogger: Logger | undefined;

interface LoggerContextBase {
    module?: string;
}

const rootBindings: Record<string, unknown> = {};

interface InitLoggingProps {
    commitHash: string;
    appName: string;
    channel?: string;
    logLevel: {
        api: LevelOrString;
        console: LevelOrString;
    };
    isConsoleLogEnabled?: boolean;
}

export function initLogging({ appName, logLevel, commitHash, channel, isConsoleLogEnabled = true }: InitLoggingProps) {
    rootLogger = pino({
        browser: {
            write: isConsoleLogEnabled ? createWrite(logLevel.console.toLowerCase()) : () => {},
            transmit: {
                level: logLevel.api.toLowerCase(),
                send,
            },
        },
    });
    rootBindings.appName = appName;
    rootBindings.commitHash = commitHash;
    rootBindings.channel = channel;
}

export function getLogger<TContext extends LoggerContextBase = LoggerContextBase>(
    context: TContext & Record<string, string>,
) {
    if (!rootLogger) throw new Error("initLogging() not yet called");
    const loggerContext = {
        ...context,
        ...rootBindings,
    };
    return rootLogger.child(loggerContext);
}
