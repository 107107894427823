import { buildDispatcher } from "@soluto-private/eventualize";
import { reactAnalytics } from "@soluto-private/eventualize-react";
import { fallbackPartner, isProd } from "../utils";
import analyticsApiSink from "./analyticsApiSink";

export const dispatcher = buildDispatcher(
    {
        appName: "expert-assist-standalone",
        appPath: window.location.pathname,
        isProd,
        platform: "ExWo",
        partner: fallbackPartner,
    },
    [analyticsApiSink],
)
    .getDispatcher()
    .withExtra({ tool: "expert-assist" });

reactAnalytics.init(dispatcher);

export const getRootDispatcher = () => {
    return reactAnalytics.dispatcher;
};
