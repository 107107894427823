import { reactAnalytics } from "@soluto-private/eventualize-react";
import type { TimelineFeatures } from "@expert/features";
import { trackFullStoryEvent } from "@expert/monitoring";
import { getLastMessage, useTimelineStore } from "../state";
import { getDefaultMessage } from "./getDefaultMessage";
import type { Partner } from "@expert/shared-types";
import { addFirstOpenMessageIfNeeded } from "./addFirstDefaultMessage";

export interface SolveSessionProps {
    sessionId: string;
    callSid?: string;
    partner: Partner;
    withCustomer: boolean;
    features: Pick<TimelineFeatures, "isProactiveEnabled">;
}

export const solveSessionStarted = ({
    sessionId,
    callSid,
    withCustomer,
    partner,
    features: { isProactiveEnabled },
}: SolveSessionProps) => {
    const { expertId, addMessage, expertName } = useTimelineStore.getState();

    addFirstOpenMessageIfNeeded(expertName);
    const lastMessage = getLastMessage();
    if (withCustomer && lastMessage?.key !== "inCallDeviceInfo") {
        //TODO: Remove hardcoded mcafee greeting message
        if (partner === "mcafee") {
            addMessage({
                key: "newMcafeeCall",
                text: "Need help with software installations, pop-up blockers, or antivirus scanning? I can help with all of that and more!",
                type: "Default",
                id: `newMcafeeCall_${crypto.randomUUID()}`,
                tag: "New call",
                isUnread: true,
                status: "active",
            });
        } else {
            addMessage({
                ...getDefaultMessage("inCallDeviceInfo"),
                id: `deviceInfo_${crypto.randomUUID()}`,
            });
        }
    }

    const extraData = {
        expertAssistType: isProactiveEnabled ? "Proactive" : "Reactive",
    };

    void reactAnalytics.dispatcher
        .withIdentities({
            SessionId: sessionId,
            ExpertAssistSessionId: sessionId,
            ExpertId: expertId,
            ...(callSid && { CallSid: callSid }),
        })
        .withExtra({ tool: "expert-assist", partner, ...extraData, withCustomer })
        .dispatchBusinessEvent("ExpertAssistSessionStarted");
    trackFullStoryEvent("ExpertAssistSessionStarted", { sessionId, callSid, partner, ...extraData, withCustomer });
};

export const solveSessionClosed = ({
    sessionId,
    callSid,
    partner,
    features: { isProactiveEnabled },
}: Omit<SolveSessionProps, "withCustomer">) => {
    const { expertId } = useTimelineStore.getState();

    const expertAssistType = isProactiveEnabled ? "Proactive" : "Reactive";
    void reactAnalytics.dispatcher
        .withIdentities({
            SessionId: sessionId,
            ExpertAssistSessionId: sessionId,
            ExpertId: expertId,
            ...(callSid && { CallSid: callSid }),
        })
        .withExtra({ tool: "expert-assist", partner, expertAssistType })
        .dispatchBusinessEvent("ExpertAssistSessionClosed");
    trackFullStoryEvent("ExpertAssistSessionClosed", { sessionId, callSid, partner, expertAssistType });
};
