/// <reference types="@dynatrace/dtrum-api-types" />
import { getLogger } from "@expert/logging";
import { injectExternalScript } from "./script-injection";

const logger = getLogger({
    module: "dynatrace",
});

interface DynatraceOptions {
    isEnabled: boolean;
    appMonitoringUrl?: string;
}

export const initDynatrace = ({ isEnabled, appMonitoringUrl }: DynatraceOptions) => {
    if (isEnabled && appMonitoringUrl) {
        injectExternalScript("DynatraceFrontendAppScript", appMonitoringUrl, "anonymous");
        logger.debug(`Dynatrace frontend app script injected.`);
    } else {
        logger.warn(`Did NOT inject dynatrace frontend app script. (This is expected in local development)`);
    }
};

interface DynatraceIdentifier {
    employeeId: string | null;
    name: string | null;
    email: string | null;
}

export const setDynatraceIdentifier = ({ name, email, employeeId }: DynatraceIdentifier) => {
    if (!window.dtrum) return;
    const nameOrEmail = name ?? email;
    if (!nameOrEmail || !employeeId) return;
    window.dtrum.identifyUser(`${nameOrEmail} - ${employeeId}`);
};
