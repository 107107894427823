import pRetry from "p-retry";
import { getLogger } from "@expert/logging";
import type { AnalyticsEventModel, ExtendedEventData } from "@soluto-private/eventualize";
import { useExpertAssistStore } from "../state";
import { env } from "../utils/env";

const logger = getLogger({ module: "Analytics" });

async function sendEvent(eventModel: AnalyticsEventModel<ExtendedEventData>) {
    return await fetch(`${env.baseUrl}/api/analytics/v1/dispatch`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${useExpertAssistStore.getState().token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(eventModel),
    });
}

async function analyticsApiSink(eventModel: AnalyticsEventModel<ExtendedEventData>) {
    try {
        await pRetry(
            async () => {
                await sendEvent(eventModel);
            },
            {
                retries: 3,
                minTimeout: 2 * 1000,
                maxTimeout: 10 * 1000,
            },
        );

        return true;
    } catch (error: unknown) {
        logger.error({ error }, "Unable to send analytics events after multiple tries");
        return false;
    }
}

export default analyticsApiSink;
