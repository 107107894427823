import { Button, Group, Modal, Stack } from "@mantine/core";
import { useReactAnalytics } from "@soluto-private/eventualize-react";

interface ToolboxActionConfirmationProps {
    isOpen: boolean;
    title: string;
    onSubmit: () => void;
    onClose: () => void;
    extraData?: Record<string, unknown>;
}

export function ToolboxActionConfirmation({
    isOpen,
    title,
    onClose,
    onSubmit,
    extraData,
}: ToolboxActionConfirmationProps) {
    const { dispatcher } = useReactAnalytics();

    const handleSubmit = () => {
        void dispatcher.dispatchBusinessEvent(`Click_Toolbox_ConfirmAction`, {
            button: "ConfirmToolboxAction",
            ...extraData,
        });
        onSubmit();
    };

    const handleClose = () => {
        void dispatcher.dispatchBusinessEvent(`Click_Toolbox_CancelAction`, {
            button: "CancelToolboxAction",
            ...extraData,
        });
        onClose();
    };

    return (
        <Modal.Root opened={isOpen} onClose={handleClose} zIndex="var(--mantine-priority-highest)" centered>
            <Modal.Content>
                <Modal.Header styles={{ header: { alignItems: "unset" } }}>
                    <Modal.Title p="1rem">{title}</Modal.Title>
                    <Modal.CloseButton onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <Stack gap="1.25rem">
                        <Group justify="flex-end">
                            <Button
                                bg="transparent"
                                color="dark.1"
                                data-testid="cancel-action-button"
                                onClick={handleClose}
                                size="sm"
                                td="underline"
                                variant="subtle"
                            >
                                Cancel
                            </Button>
                            <Button
                                size="sm"
                                onClick={handleSubmit}
                                data-testid="confirm-action-button"
                                variant="filled"
                            >
                                Yes
                            </Button>
                        </Group>
                    </Stack>
                </Modal.Body>
            </Modal.Content>
        </Modal.Root>
    );
}
