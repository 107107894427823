import type {
    ReactiveMessagePayload,
    ProactiveMessagePayload,
    GaiaErrorBody,
    ReactiveMessageTypes,
} from "@expert/shared-types";

const autoCompleteValidator: ReactiveMessageTypes[] = ["reactive-autocomplete-ext", "reactive-autocomplete"];
const reactiveValidator: ReactiveMessageTypes[] = ["reactive-solve-ext", "reactive-solve"];

export const isAutoCompleteMessage = (body: ReactiveMessagePayload) => {
    return !!body.metadata?.messageType && autoCompleteValidator.includes(body.metadata.messageType);
};

export const isReactiveMessage = (body: ReactiveMessagePayload) => {
    return !!body.metadata?.messageType && reactiveValidator.includes(body.metadata.messageType);
};

export const isReactiveErrorMessage = (body: GaiaErrorBody) => {
    return body.messageType === "Search";
};

export const isProactiveMessage = (body: Partial<ProactiveMessagePayload>) => {
    const suggestion = body.suggestion;
    if (!suggestion?.message) return false;
    return suggestion.messageType === "Proactive" && suggestion.producer === "orchestrator";
};

export const isProactiveErrorMessage = (body: Partial<ProactiveMessagePayload>) => {
    const message = body.suggestion?.message;
    if (!message) return false;
    return (
        message.toLowerCase().startsWith("internal server error") ||
        message.toLowerCase().startsWith("internal system error")
    );
};
